:root {
  --main-color: #f55856;
}

span.form-error {
  color: #ff4842;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.editor-static.is-invalid {
  border: 1px solid #ff4842;
  box-shadow: 1px 0px 2px #ff4842;
}

.editor-content-show p {
  margin: 0;
}

.jodit-react-container p,
.jodit-react-container ol {
  margin: 0 !important;
}
.jodit-react-container ol {
  padding-left: 0;
}

.jodit-wysiwyg {
  padding-left: 10px;
}
ul {
  list-style: inside;
}
ol {
  list-style: inside;
  list-style-type: number;
}
td {
  border: solid 1px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  gap: 8px;
}

.couponTemplate {
  margin-left: 0 !important;
  margin-bottom: 8px !important;
  position: relative;
}

.couponTemplate svg {
  position: absolute;
  top: -4px;
  right: -6px;
  font-size: 17px;
  color: #c61313;
  cursor: pointer;
}

.rdt_TableHead {
  background-color: #f55856 !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}

.rdt_TableHeadRow {
  background-color: #f55856 !important;
}

.coupon_timepicker .css-1w5whbz-MuiStack-root {
  /* padding-top: 0 !important; */
}

.error-msg {
  color: #ff4842;
  font-size: 12px;
}

.filter_con {
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter_wrp {
  min-width: 180px;
  /* margin: 10px; */
}

.custom_datepicker {
  position: relative;
}

.custom_datepicker .calendar_cust_icon svg {
  position: absolute;
  top: 16px;
  right: 21px;
  color: grey;
  z-index: -1;
}
.custom_datepicker .react-datepicker {
  display: flex;
}
.custom_datepicker .react-datepicker__input-container input {
  padding: 10px;
  margin-top: 8px;
  margin-right: 15px;
  width: 230px;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
}
.react-datepicker__close-icon {
  top: 3px !important;
  right: 15px !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--main-color) !important;
}

.react-datepicker__close-icon::after {
  height: 20px !important;
  width: 20px !important;
  padding: 2px !important;
  font-size: 17px !important;
}
.custom_datepicker .react-datepicker__input-container input:focus-visible {
  outline: 1px !important;
}
.react-datepicker__day--in-range {
  background-color: var(--main-color) !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: var(--main-color) !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker__day--today {
  font-weight: normal !important;
}

.react-datepicker__header {
  background-color: var(--main-color) !important;
  /* color: #fff; */
}

.react-datepicker__current-month {
  color: #fff !important;
}
.react-datepicker__day-name {
  color: #fff !important;
  font-weight: bold;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
}
.react-datepicker__day,
.react-datepicker__day-name {
  margin: 0.3rem !important;
}

.noDateSelected .react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
}

/* address css */
.address_con {
  position: relative;
}

.address_con .suggestion-box {
  position: absolute;
  top: 62px;
  left: 0;
  max-height: 300px;
  background-color: #fbfbfb;
  overflow-y: auto;
  width: 100%;
  z-index: 11;
  border-radius: 6px;
  box-shadow: 0px 5px 5px -3px rgba(145, 158, 171, 0.2),
    0px 8px 10px 1px rgba(145, 158, 171, 0.14),
    0px 3px 14px 2px rgba(145, 158, 171, 0.12);
}

.address_con .suggestion-box li {
  list-style: none;
  padding: 6px 15px;
  cursor: pointer;
}

.address_con .suggestion-box li:hover {
  background-color: rgba(145, 158, 171, 0.08);
}

.listing_card {
  overflow: visible !important;
}

.merchant_btn_con {
  display: flex;
  gap: 5px;
}

.merchant_btn_con button {
  padding: 0 !important;
}
